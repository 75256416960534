<template>
  <div class="login">
    Добро пожаловать
  </div>
</template>

<script>


export default {
  name: 'Main',
}
</script>
